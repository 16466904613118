import React from 'react'
import ReactDOM from 'react-dom'
import { useContext } from 'react'
import { ThemeContext } from '../../context/ThemeContext'
import { useNavigate } from 'react-router-dom'
import { FacebookIcon } from '../icons/social/facebook'
import { InstagramIcon } from '../icons/social/instagram'
import { useEffect, useState } from 'react'
import { YoutubeIcon } from '../icons/social/youtube'
import { FlickrIcon } from '../icons/social/flickr'
import { useApp } from '../../context/app'
import { PhoneIcon } from '../icons/common/phone'
import { SinaisIcon } from '../icons/acessibilidade/sinais'
import { ContrasteIcon } from '../icons/acessibilidade/contraste'
import { FonteAumentar } from '../icons/acessibilidade/FonteAumentar'
import { FonteReset } from '../icons/acessibilidade/FonteReset'
import { FonteDiminuir } from '../icons/acessibilidade/FonteDiminuir'
import { AccessibilidadeIcon } from '../icons/acessibilidade/accessibilidade'
import VLibras from '@djpfs/react-vlibras'
import './style.css'
import { FontSizeContext } from '../../context/FontSizeProvider.jsx'

export function ToolBar() {
	const navigate = useNavigate()
	const { toggleTheme } = useContext(ThemeContext)
	const { tamanhoFonte } = useContext(FontSizeContext)

	const { mobile, setVisible, visible } = useApp()
	const [tema_escuro, setTema] = useState<number>(0)

	/*Start - FONT RESIZE*/
	const [esconderFonteReset, setEsconderFonteReset] = useState<boolean>(false)
	const pagina_atual = window.location.pathname
	const pagina_atual_Array = pagina_atual.split('/')

	const fontResize = [
		'body',
		'.ToolBar-botoes',
		'.ToolBar-contato-btn',
		'.Header-navBarHover',
		'.Footer-endereco',
	]

	const boxResize = [
		'.ToolBar-container',
		'.Header-itens',
		'.MapSite-container',
		'.Footer-container',
	]

	const multiFontResize = [
		'.hover-submenu-item-itemMenuNav',
		'.MapSite-TitleInfo',
		'.MapSite-txt',
		'.Footer-col',
	]
	if (pagina_atual == '/') {
		//HOME

		fontResize.push(
			'.hover-submenu-box',
			'.MaisNoticias-titulo',
			'.AcessoRapido-titulo',
			'.News-btn a',
			'.Search-form input',
			'.item-container',
			'.Services-bottom'
		)

		boxResize.push(
			'.Search-container',
			'.MaisNoticias-container',
			'.ListBottomNews',
			'.News-btn',
			'.AcessoRapido-titulo-container',
			'.AcessoRapido-container',
			'.Services-container'
		)

		multiFontResize.push(
			'.BottomNews-titulo',
			'.BottomNews-data',
			'.AcessoRapido-card-titulo',
			'.AcessoRapido-card-txt',
			'.Services-btn'
		)
	}

	if (
		//NOTICIA
		pagina_atual_Array[1] == 'noticias' &&
		!!pagina_atual_Array[2]
	) {
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.pages-News-subtitle',
			'.pages-News-data',
			'.pages-News-conteudo',
			'.Header-search-form-input'
		)

		boxResize.push('.TitlePage', '.pages-News-container')
	}

	if (
		//listNews
		pagina_atual_Array[1] == 'noticias' &&
		!pagina_atual_Array[2]
	) {
		fontResize.push(
			'.Header-breadcrumb-item',
			'.TitlePage-iconNews-titulo',
			'.Header-search-form-input'
		)

		boxResize.push('.TitlePage', '.pages-ListNews-container')

		multiFontResize.push(
			'.page-ListNews-ItemCategorie',
			'.pages-ListNews-titulo',
			'.pages-ListNews-data',
			'.pages-ListNews-layoutGrid-titulo',
			'.pages-ListNews-layoutGrid-resumo',
			'.pages-ListNews-layoutGrid-data'
		)
	}

	if (
		//ENTIDADES
		pagina_atual_Array[1] == 'a' &&
		!!pagina_atual_Array[2]
	) {
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.Header-breadcrumb-item',
			'.Header-search-form-input'
		)

		boxResize.push('.TitlePage', '.pages-Entity-container')

		multiFontResize.push(
			'.pages-Entity-itemCategorie',
			'.SubTitlePage-titulo',
			'.pages-Entity-linha-txt'
		)
	}

	if (
		//ARTIGOS
		pagina_atual_Array[1] == 'e' &&
		!!pagina_atual_Array[2]
	) {
		fontResize.push(
			'.TitlePage-iconNews-titulo',
			'.Header-breadcrumb-item',
			'.Header-search-form-input'
		)

		boxResize.push('.TitlePage', '.pages-Article-container')

		multiFontResize.push('.pages-Article-conteudo h3')
	}

	if (
		//LOCALIZAÇÃO
		pagina_atual_Array[1] == 'localizacao' &&
		!pagina_atual_Array[2]
	) {
		fontResize.push('.Header-breadcrumb-item', '.Header-search-form-input')

		boxResize.push('.TitlePage', '.pages-Location-container')

		multiFontResize.push('.pages-Location-titulo', '.pages-Location-txt')
	}

	if (
		//TELEFONES
		pagina_atual_Array[1] == 'telefones' &&
		!pagina_atual_Array[2]
	) {
		fontResize.push(
			'.Header-breadcrumb-item',
			'.pages-Contact-destaque-titulo',
			'.pages-Contact-destaque-telefone',
			'.pages-Contact-Title',
			'.Header-search-form-input'
		)

		boxResize.push('.TitlePage', '.pages-Contact-container')

		multiFontResize.push('.pages-Contact-Item')
	}

	//console.log( 'pagina_atual', pagina_atual );
	//console.log( 'pagina_atual_Array', pagina_atual_Array );
	//console.log( 'fontResize', fontResize );
	//console.log( 'boxResize', boxResize );
	//console.log( 'multiFontResize', multiFontResize );

	const { aumentarFonte, diminuirFonte, resetFonte } =
		useContext(FontSizeContext)

	const contraste = (toggleTheme: () => void) => {
		try {
			toggleTheme() // Chama a função do ThemeProvider para alternar o tema
		} catch (error) {
			console.log('components/TooBar - contraste - Erro: ', error)
		}
	}

	if (mobile) {
		return (
			<div className="ToolBarMobile">
				<div className="ToolBarMobile-container">
					<div className="ToolBar-contato-btn telefone">
						<a href="tel:+4733416000" target="_self">
							<PhoneIcon />
							<span>(47) 3341-6000</span>
						</a>
					</div>

					{/*
					
						<div 
							className="ToolBar-acessibilidade-btn"
							onClick={ () => contraste(1) }
						><FonteDiminuir /></div>
						
						<div 
							className="ToolBar-acessibilidade-btn"
							onClick={ () => aumentarFonte() }
						><FonteAumentar /></div>
					
					*/}

					<a
						href="/e/177"
					>
						<div className="ToolBar-acessibilidade-btn">
							<AccessibilidadeIcon />
						</div>
					</a>

					<div
						className="ToolBar-acessibilidade-btn"
						onClick={() => contraste(toggleTheme)}
					>
						<ContrasteIcon />
					</div>

					<div className="ToolBar-contato-container">
						<div className="ToolBar-contato-btn">
							<a
								href="https://www.facebook.com/PrefeituradeItajai"
								target="_blank"
								rel="noreferrer"
							>
								<FacebookIcon />
							</a>
						</div>

						<div className="ToolBar-contato-btn">
							<a
								href="https://www.instagram.com/prefeituradeitajai/"
								target="_blank"
								rel="noreferrer"
							>
								<InstagramIcon />
							</a>
						</div>

						<div className="ToolBar-contato-btn">
							<a
								href="https://www.youtube.com/MunicipiodeItajai"
								target="_blank"
								rel="noreferrer"
							>
								<YoutubeIcon />
							</a>
						</div>

						<div className="ToolBar-contato-btn">
							<a
								href="https://www.flickr.com/photos/secomitajai/sets/"
								target="_blank"
								rel="noreferrer"
							>
								<FlickrIcon />
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="ToolBar">
			<div className="ToolBar-container">
				<div className="ToolBar-botoes">
					<a
						href="https://servicos.itajai.sc.gov.br/servico/79-ouvidoria.html"
						target="_blank"
						rel="noreferrer"
					>
						Ouvidoria
					</a>

					<a
						href="https://servicos.itajai.sc.gov.br/servico/69-acesso_a_informacao.html"
						target="_blank"
						rel="noreferrer"
					>
						e-SIC
					</a>

					<a onClick={() => navigate('/contato')}>Telefones</a>

					<a
						href="https://intranet2.itajai.sc.gov.br/jornal-municipio/portal/"
						target="_blank"
						rel="noreferrer"
					>
						Diário Oficial
					</a>

					<a
						href="https://portaltransparencia.itajai.sc.gov.br/epublica-portal/#/itajai/portal?entidade=411"
						target="_blank"
						rel="noreferrer"
					>
						Transparência
					</a>
				</div>

				<div className="ToolBar-acessibilidade-botoes">
					<div
						className="ToolBar-acessibilidade-btn"
						onClick={() => {
							setVisible(!visible)
							//console.log(visible)
						}}
					>
						<SinaisIcon />
					</div>
					<div
						className="ToolBar-acessibilidade-btn"
						onClick={() => contraste(toggleTheme)}
					>
						<ContrasteIcon />
					</div>

					<div
						className="ToolBar-acessibilidade-btn"
						style={{ display: tamanhoFonte > -1 ? 'block' : 'none' }}
						onClick={() => diminuirFonte()}
					>
						<FonteDiminuir />
					</div>

					{!!esconderFonteReset && (
						<div
							className="ToolBar-acessibilidade-btn"
							onClick={() => resetFonte()}
						>
							<FonteReset />
						</div>
					)}

					<div
						className="ToolBar-acessibilidade-btn"
						onClick={() => aumentarFonte()}
						style={{ display: tamanhoFonte < 1 ? 'block' : 'none' }} // Esconde o botão se a fonte estiver no tamanho máximo
					>
						<FonteAumentar />
					</div>

					<a href="https://educacao.itajai.sc.gov.br/e/139">
						<div className="ToolBar-acessibilidade-btn">
							<AccessibilidadeIcon />
						</div>
					</a>
				</div>

				<div className="ToolBar-contato-container">
					<div className="ToolBar-contato-btn telefone">
						<a href="tel:+4733416000" target="_self">
							<PhoneIcon />
							<span>(47) 3341-6000</span>
						</a>
					</div>

					<div className="ToolBar-contato-btn">
						<a
							href="https://www.facebook.com/PrefeituradeItajai"
							target="_blank"
							rel="noreferrer"
						>
							<FacebookIcon />
						</a>
					</div>

					<div className="ToolBar-contato-btn">
						<a
							href="https://www.instagram.com/prefeituradeitajai/"
							target="_blank"
							rel="noreferrer"
						>
							<InstagramIcon />
						</a>
					</div>

					<div className="ToolBar-contato-btn">
						<a
							href="https://www.youtube.com/MunicipiodeItajai"
							target="_blank"
							rel="noreferrer"
						>
							<YoutubeIcon />
						</a>
					</div>

					<div className="ToolBar-contato-btn">
						<a
							href="https://www.flickr.com/photos/secomitajai/sets/"
							target="_blank"
							rel="noreferrer"
						>
							<FlickrIcon />
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
