import React from 'react'
import { useEffect, useState } from 'react'
import { Header } from "../../components/Header";
import { News } from "../../components/News";
import { Search } from "../../components/Search";
import { Services } from "../../components/Services";
import bannerCovid from "../../assets/imgs/bannerCovid.png"
import { Videos } from "../../components/Videos";
import { Conectai } from "../../components/Conectai";
import { MapSite } from "../../components/MapSite";
import { Footer } from "../../components/Footer";
import { Banner } from "../../components/Banner";
import { Popup } from "../../components/Popup";
import { useApp } from "../../context/app";
import { ServicesAccordion } from "../../components/ServicesAccordion";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { AcessoRapido } from "../../components/AcessoRapido";
import VLibras from '@djpfs/react-vlibras';
import { api, urls } from "../../services/api"
import { IModal } from '../../interfaces/modal';
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function HomeScreen() {

	const { mobile, menuMobile, visible } = useApp()

	const [ativarPopup, setPopup] = useState(false)

	useEffect(() => {
		(async () => {
			try {

				const responseModal = await api.get<IModal[]>(urls.Modal)

				//console.log('responseModal.data:', responseModal.data)
				//console.log('responseModal.data.length:', responseModal.data.length)

				if (responseModal.data.length != 0) { setPopup(true) }

			} catch (error) {
				console.log('responseModal.data.error:', error)
			}
		})()
	}, [])

	return (
		<div className="pages-Home">

			<>

				{

					ativarPopup &&

					<Popup />

				}

				{

					menuMobile ?

						(

							<HeaderMobile />

						)

						:

						(

							<Header />

						)

				}

				{

					mobile ?

						(

							<SearchMobile />

						)

						:

						(

							<Search />

						)

				}

				{

					<div className="Banner">
						<div className="Banner-container">
							<a
								href="/e/181"
								target='_self'
							>
								<img
									src={`https://api-portal.itajai.sc.gov.br/public/portaladm-pmitajai/banners/1faead8f-6565-4ae3-843a-6ef372af25dd.jpg`}
									className="d-block w-100 Banner-img"
								/>
							</a>
						</div>
					</div>

				}

				<News />

				<AcessoRapido />

				{

					mobile ?

						(

							<ServicesAccordion />

						)

						:

						(

							<Services />

						)

				}

				<Banner mobile={mobile} />

				<Videos mobile={mobile} />

				<Conectai />

				<MapSite />

				<Footer />

				{

					visible &&

					(

						<VLibras forceOnload />

					)

				}

			</>

		</div>

	)

}