import { useApp } from "../../context/app"
import "./style.css"

export function Footer() {

	const { mobile } = useApp()

	return (

		<div className="Footer">

			<div className="Footer-container">

				<div className="Footer-endereco">Rua Alberto Werner, 100 . Bairro: Vila Operária . Itajaí - SC . CEP: 88304-900</div>

				<div className="Footer-bottom">

					<div className="Footer-col">© {new Date().getFullYear()} Município de Itajaí - Todos os direitos reservados</div>

					<div className="Footer-col">Desenvolvimento SETEC</div>

				</div>

			</div>

		</div>

	)

}