import { createContext, useContext, useEffect, useState } from 'react'
import useWindowDimensions from '../hooks/Dimensions'
import { ThemeProvider as StyledThemeProvider } from 'styled-components' // Mantemos o ThemeProvider do styled-components
import { ThemeContext, ThemeProvider } from '../context/ThemeContext' // Importe seu ThemeProvider e ThemeContext
import { api } from '../services/api'
import { FontSizeProvider } from './FontSizeProvider.jsx'

interface IAppContext {
	mobile: boolean
	visible: boolean
	setVisible: React.Dispatch<React.SetStateAction<boolean>>
	menuMobile: boolean
	inputSearch: string
	smallScreen: boolean
	setInputSearch: React.Dispatch<React.SetStateAction<string>>
	configDomainURL: string
	basename: string
	configApiURL: string
}

const AppContext = createContext({} as IAppContext)

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
	const configDefault = require('../ini.json')
	const [basename, setBasename] = useState('')
	const [configDomainURL, setConfigDomainURL] = useState(
		configDefault.domain_url
	)
	const [configApiURL, setConfigApiURL] = useState(configDefault.api_url)
	const [mobile, setMobile] = useState<boolean>(false)
	const [menuMobile, setMenuMobile] = useState<boolean>(false)
	const [smallScreen, setSmallScreen] = useState<boolean>(false)
	const [inputSearch, setInputSearch] = useState<string>('')
	const { width } = useWindowDimensions()
	const [visible, setVisible] = useState<boolean>(false)

	const tamanho_mobile = 1024
	const tamanho_tablet = 1300

	useEffect(() => {
		if (tamanho_mobile > width) {
			setMobile(true)
			console.log('É mobile')
		} else {
			setMobile(false)
		}
	}, [width])

	useEffect(() => {
		if (tamanho_mobile <= width) {
			setSmallScreen(true)
		} else {
			setSmallScreen(false)
		}
	}, [width])

	useEffect(() => {
		if (tamanho_mobile > width) {
			setMenuMobile(true)
		} else {
			setMenuMobile(false)
		}
	}, [width])

	useEffect(() => {
		; (async () => {
			const response = await fetch(
				`${window.location.hostname === 'localhost' ? 'http://localhost:3000' : window.location.hostname === 'appdev' ? 'http://appdev/portais/portal-municipio/deploy' : `https://${window.location.hostname}`}/ini.json`,
				{
					headers: {
						Accept: 'application/json',
					},
				}
			)
			const configJson = await response.json()
			api.defaults.baseURL = `${configJson.api_url}portaladm-educacao/api/`
			setConfigApiURL(configJson.api_url)
			setConfigDomainURL(configJson.domain_url)
			setBasename(configJson.basename)
		})()
	}, [])

	return (
		<ThemeProvider>
			<FontSizeProvider>
				{' '}
				{/* Usando o seu ThemeProvider */}
				<AppContext.Provider
					value={{
						mobile,
						menuMobile,
						smallScreen,
						setVisible,
						visible,
						inputSearch,
						setInputSearch,
						configDomainURL,
						basename,
						configApiURL,
					}}
				>
					<StyledThemeProvider theme={ThemeContext}>
						{' '}
						{/* Mantemos o ThemeProvider do styled-components */}
						{children}
					</StyledThemeProvider>
				</AppContext.Provider>
			</FontSizeProvider>
		</ThemeProvider>
	)
}

export function useApp() {
	const context = useContext(AppContext)
	return context
}
