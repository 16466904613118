import { createContext, useEffect, useState } from 'react'

// Criando o contexto do tema
export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(
		localStorage.getItem('theme') || 'light' // Recupera o tema salvo no localStorage
	)

	// Aplica a classe do tema no body e salva no localStorage
	useEffect(() => {
		if (theme === 'dark') {
			document.body.classList.add('tema_escuro')
		} else {
			document.body.classList.remove('tema_escuro')
		}
		localStorage.setItem('theme', theme)
	}, [theme])

	// Alternar entre Light e Dark Mode
	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
	}

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	)
}
