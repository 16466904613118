import { createContext, useState, useEffect } from 'react'

export const FontSizeContext = createContext()

export const FontSizeProvider = ({ children }) => {
	const fontResize = [
		'p',
		'h1',
		'h2',
		'h3',
		'h4',
		'h5',
		'h6',
		'span',
		'li',
		'label',
		'button',
		'.pages-News-conteudo',
		'.TitlePage-iconNews-titulo',
		'.pages-News-subtitle',
		'.pages-News-data',
		'.MapSite-TitleInfo',
		'.MapSite-txt',
		'.Footer-endereco',
		'.AcessoRapido-card-titulo',
		'.Header-navBarHover',
	]
	const boxResize = ['.box-ajustavel']
	const multiFontResize = ['.multi-texto-ajustavel']

	// Inicializa o estado baseado no localStorage
	const [esconderFonteReset, setEsconderFonteReset] = useState(
		JSON.parse(localStorage.getItem('esconderFonteReset')) || false
	)
	const [tamanhoFonte, setTamanhoFonte] = useState(
		JSON.parse(localStorage.getItem('tamanhoFonte')) || 0
	)

	useEffect(() => {
		// Sempre que o estado mudar, salva no localStorage
		localStorage.setItem(
			'esconderFonteReset',
			JSON.stringify(esconderFonteReset)
		)
		localStorage.setItem('tamanhoFonte', JSON.stringify(tamanhoFonte))
	}, [esconderFonteReset, tamanhoFonte])

	const alterarEstilo = (seletor, callback) => {
		document.querySelectorAll(seletor).forEach((el) => {
			callback(el)
		})
	}

	const resetFonte = () => {
		fontResize.forEach((alvo) =>
			alterarEstilo(alvo, (el) => el.removeAttribute('style'))
		)
		boxResize.forEach((alvo) =>
			alterarEstilo(alvo, (el) => el.removeAttribute('style'))
		)
		multiFontResize.forEach((alvo) =>
			alterarEstilo(alvo, (el) => el.removeAttribute('style'))
		)
		setTamanhoFonte(0) // Resetando o tamanho da fonte
		setEsconderFonteReset(false)
	}

	const ajustarFonte = (ajuste) => {
		fontResize.forEach((alvo) => {
			alterarEstilo(alvo, (el) => {
				let fontSize = parseFloat(
					window.getComputedStyle(el).getPropertyValue('font-size')
				)
				let lineHeight = parseFloat(
					window.getComputedStyle(el).getPropertyValue('line-height')
				)
				el.setAttribute(
					'style',
					`font-size:${fontSize + ajuste}px; line-height:${lineHeight + ajuste}px;`
				)
			})
		})

		multiFontResize.forEach((alvo) => {
			alterarEstilo(alvo, (el) => {
				let fontSize = parseFloat(
					window.getComputedStyle(el).getPropertyValue('font-size')
				)
				let lineHeight = parseFloat(
					window.getComputedStyle(el).getPropertyValue('line-height')
				)
				el.setAttribute(
					'style',
					`font-size:${fontSize + ajuste}px; line-height:${lineHeight + ajuste}px;`
				)
			})
		})

		setTamanhoFonte(tamanhoFonte + ajuste) // Alterando o estado de tamanho
	}

	const aumentarFonte = () => {
		if (tamanhoFonte < 1) {
			ajustarFonte(3) // Aumenta a fonte em 5px
		}
	}

	const diminuirFonte = () => {
		if (tamanhoFonte > -1) {
			ajustarFonte(-3) // Diminui a fonte em 5px
		}
	}

	return (
		<FontSizeContext.Provider
			value={{
				aumentarFonte,
				diminuirFonte,
				resetFonte,
				tamanhoFonte,
				esconderFonteReset, // Passando o estado da visibilidade
			}}
		>
			{children}
		</FontSizeContext.Provider>
	)
}
